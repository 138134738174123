import React from 'react'
import { useFormContext } from 'react-hook-form'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import CircularProgress from '@mui/material/CircularProgress'

export default function FieldButton({ children, icon, loading, onSubmit, ...props }) {
  const { handleSubmit, formState } = useFormContext()

  props.disabled = props.disabled || formState.isSubmitting

  if (loading === undefined) {
    loading = formState.isSubmitting && (props.type === 'submit' || Boolean(onSubmit))
  }

  if (onSubmit) {
    props.onClick = handleSubmit(onSubmit)
  }

  const Component = icon ? IconButton : Button
  children = icon || children

  return (
    <Component {...props}>
      {children}
      {loading && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={20} />
        </div>
      )}
    </Component>
  )
}
