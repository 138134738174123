import React from 'react'
import { FormProvider } from 'react-hook-form'
import Box from '@mui/material/Box'

export default function Form({ form, onSubmit, spacing, ...props }) {
  if (onSubmit) {
    props.onSubmit = form.handleSubmit(onSubmit)
  }

  if (spacing) {
    const marginTop = theme =>
      (typeof spacing === 'boolean'
        ? theme.spacing(2)
        : typeof spacing === 'number'
        ? theme.spacing(spacing)
        : spacing) + ' !important'
    props.sx = props.sx || {}
    props.sx['&>*+*'] = { marginTop }
  }

  return (
    <FormProvider {...form}>
      <Box component="form" autoComplete="off" noValidate {...props} />
    </FormProvider>
  )
}
