import React from 'react'
import { useFormContext, useController } from 'react-hook-form'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import Switch from '@mui/material/Switch'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import MenuItem from '@mui/material/MenuItem'

const defaultErrorText = {
  required: '必須項目です',
  pattern: '使用できない文字が含まれています',
}

function getErrorMessage(error, errorText = {}) {
  if (!error) return ''
  if (typeof errorText === 'object') errorText = errorText[error.type]
  if (typeof errorText === 'function') errorText = errorText(error)
  if (typeof errorText === 'string') return errorText
  return defaultErrorText[error.type] || error.message || ''
}

export default function Field({ name, rules = {}, errorText, helperText, options, SelectProps = {}, ...props }) {
  if (props.required) rules.required = true

  const { control, formState } = useFormContext()
  props.disabled = props.disabled || formState.isSubmitting

  const defaultValue = /^(checkbox|switch)$/.test(props.type) ? false : ''
  const { field } = useController({ name, control, rules, defaultValue })

  const error = name.split('.').reduce((errors, name) => (errors ? errors[name] || null : null), formState.errors)
  const errorMessage = getErrorMessage(error, errorText)

  if (props.type === 'checkbox' || props.type === 'switch') {
    const Control = props.type === 'checkbox' ? Checkbox : Switch
    const label = props.label || ''
    return <FormControlLabel control={<Control />} label={label} checked={field.value} {...field} {...props} />
  }

  if (props.type === 'radio') {
    if (options) {
      props.children = options.map(x => {
        const value = typeof x === 'string' ? x : x.value
        const label = typeof x === 'string' ? x : x.label
        return <FormControlLabel key={value} value={value} label={label} control={<Radio />} />
      })
    }
    return (
      <FormControl fullWidth disabled={props.disabled} error={Boolean(error)}>
        {props.label && <FormLabel children={props.label} />}
        <RadioGroup {...field} {...props} />
        {(errorMessage || helperText) && <FormHelperText children={errorMessage || helperText} />}
      </FormControl>
    )
  }

  if (options) {
    const Component = SelectProps.native ? props => <option {...props} /> : MenuItem
    props.children = options.map(x => {
      const value = typeof x === 'string' ? x : x.value
      const label = typeof x === 'string' ? x : x.label
      return <Component key={value} value={value} children={label} />
    })
    props.select = true
    props.SelectProps = { displayEmpty: options.some(x => x === '' || x.value === ''), ...SelectProps }
  }

  return <TextField fullWidth error={Boolean(error)} helperText={errorMessage || helperText} {...field} {...props} />
}
